<template>
  <footer aria-labelledby="footer-heading" class="bg-gray-50 pt-10 text-gray-800 dark:bg-gray-900 dark:text-gray-400">
    <div class="mx-auto max-w-xl px-4 md:px-6 xl:px-20">
      <cgn-newsletter-sign-up v-slot="newsletter" newsletter-type="cognito">
        <h1 class="mb-3 text-lg font-semibold leading-4 text-gray-800 dark:text-white xl:text-xl xl:leading-5">
          Join my mailing list today!
        </h1>
        <form @submit.prevent="newsletter.submitted">
          <div class="flex w-full flex-col justify-center md:flex-row">
            <cgn-form-input-text v-model="newsletter.signup.first_name" placeholder="First Name" class="md:mr-2" />
            <cgn-form-input-email v-model="newsletter.signup.email" placeholder="Email" class="md:mr-2" />
            <cgn-button color-brand>
              Join
            </cgn-button>
          </div>
        </form>
        <div class="mx-auto mt-4 max-w-md text-center">
          <cgn-spinner v-if="newsletter.is_loading" />
          <cgn-alert-success v-if="newsletter.success">
            We sent you an email to confirm your subscription
          </cgn-alert-success>
          <cgn-alert-danger v-if="newsletter.fail">
            {{ newsletter.message }}
          </cgn-alert-danger>
        </div>
      </cgn-newsletter-sign-up>
    </div>
    <div class="container mx-auto px-4 py-12 sm:px-6 lg:px-12 xl:px-20">
      <div class="flex flex-col items-center justify-center">
        <div>
          <cgn-organisation-logo class="w-40 lg:w-52" />
        </div>
        <div class="mt-8 flex flex-wrap items-center justify-center gap-8 sm:gap-10">
          <router-link to="/about" class="cursor-pointer text-base leading-4 hover:text-gray-500 dark:hover:text-white">
            About
          </router-link>
          <router-link to="/contact" class="cursor-pointer text-base leading-4 hover:text-gray-500 dark:hover:text-white">
            Contact
          </router-link>
          <router-link to="/terms-and-conditions" class="cursor-pointer text-base leading-4 hover:text-gray-500 dark:hover:text-white">
            Terms and Conditions
          </router-link>
          <router-link to="/cart" class="cursor-pointer text-base leading-4 hover:text-gray-500 dark:hover:text-white">
            Shipping Quote
          </router-link>
        </div>
        <!--
        <div class="flex items-center gap-x-8 mt-6">
          <a href="https://www.facebook.com/sanchiam" aria-label="facebook" class="rounded-full dark:hover:text-white" target="_blank">
            <i-fa-brands:facebook />
          </a>
          <a href="https://www.instagram.com/artbysanchia/" target="_blank" aria-label="instagram" class="rounded-full dark:hover:text-white">
            <i-fa-brands:instagram />
          </a>
          <a href="https://www.youtube.com/user/SanchiaMarshall" target="_blank" aria-label="instagram" class="rounded-full dark:hover:text-white">
            <i-fa-brands:youtube />
          </a>
        </div>
        -->
        <div class="mt-6 flex items-center">
          <p class="text-base leading-4">
            &copy;
            {{ year }}
            <span class="font-semibold">Sanchia Marshall</span>
          </p>
          <div class="ml-2 border-l border-gray-800 pl-2 dark:border-gray-600">
            <p class="text-base leading-4">
              All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const year = ref(new Date().getFullYear())
</script>
