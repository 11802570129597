<template>
  <NavBarBlock />

  <main
    class="prose-headings:cgn-heading prose-h1:cgn-heading-1 prose-h2:cgn-heading-2 prose-h3:cgn-heading-3 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 pb-10 text-gray-800 dark:from-darkbg-500 dark:to-darkbg-800 dark:text-white"
  >
    <cgn-page-router />
  </main>
  <cgn-newsletter-popup v-slot="newsletter" success-message="We sent you an email to confirm your subscription" newsletter-type="cognito">
    <div class="text-2xl font-bold">
      While you're here, join me!
    </div>
    <p class="mt-3">
      Be notified when new creations are available and keep up to date with studio happenings!
    </p>
    <form @submit.prevent="newsletter.submitted">
      <cgn-form-input-text v-model="newsletter.signup.first_name" label="First Name" required />
      <cgn-form-input-email v-model="newsletter.signup.email" label="Email" required />
      <cgn-button color-brand>
        Sign me up!
      </cgn-button>
    </form>
  </cgn-newsletter-popup>

  <Footer />
</template>

<script setup lang="ts">
</script>
