<template>
  <div class="dark:bg-gray-900 dark:text-gray-400 ">
    <div>
      <div class="relative">
        <!-- For large screens -->
        <div class="dark:bg-gray-900 bg-gray-50 px-6 py-3">
          <div class="container mx-auto flex items-center justify-between">
            <router-link to="/" class="md:w-2/12">
              <cgn-organisation-logo class="w-40 lg:w-52" />
            </router-link>
            <ul class="hidden w-8/12 md:flex items-center justify-center space-x-8 dark:text-white text-base text-gray-800">
              <li v-for="menu in menus" :key="menu.link">
                <router-link :to="menu.link" class="hover:underline">
                  {{ menu.name }}
                </router-link>
              </li>
            </ul>

            <div class="md:w-2/12 justify-end flex items-center space-x-4 xl:space-x-8">
              <div class="flex items-center lg:space-x-4 xl:space-x-8">
                <cgn-dark-mode-switch
                  class="text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-200 mr-3"
                />
                <NavBarQuickSearch />
                <NavBarCart />
                <NavBarProfileButton />
              </div>

              <div class="flex lg:hidden">
                <button aria-label="open menu" class="text-black dark:text-white dark:hover:text-gray-300 md:hidden rounded " @click="menu = true">
                  <i-tabler:align-right class="w-10" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- For small screen -->
        <div v-if="menu" id="mobile-menu" class="absolute dark:bg-gray-900 z-50 inset-0 md:hidden bg-white flex flex-col h-screen w-full">
          <div class="flex justify-between border-b border-gray-200 dark:border-gray-700 pb-4 p-4">
            <router-link to="/" class="md:w-2/12 cursor-pointer text-gray-800 dark:text-white">
              <cgn-organisation-logo class="w-d2" />
            </router-link>
            <i-heroicons-solid:x class="cursor-pointer" @click="menu = false" />
          </div>
          <div class="mt-6 p-4">
            <ul class="flex flex-col space-y-6 text-base text-gray-800 dark:text-white ">
              <li v-for="menu in menus" :key="menu.link">
                <router-link :to="menu.link" class="flex items-center justify-between hover:underline">
                  {{ menu.name }}
                  <div>
                    <i-heroicons-solid:chevron-right />
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const menu = ref(false)
const router = useRouter()
const menus = ref<CognitoMenu[]>([])

router.beforeEach(() => {
  menu.value = false
})

async function loadMenu() {
  menus.value = await new CognitoMenu().getMenu('Main Menu', '16x3', '1920')
}

onMounted(() => {
  loadMenu()
})

onServerPrefetch(async () => {
  await loadMenu()
})
</script>
